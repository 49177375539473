import React from "react";
import './PracticeAreas.scss';
import {sectionNames} from "../../common/consts";
import Section from "../../common/Section/Section";
import {Cards} from "./Cards/Cards";
import {useWindowWidth} from "@react-hook/window-size";

const PracticeAreas = () => {
    const width = useWindowWidth();

    return (
        <Section expandable={true}>
            <div>
                <div className={"mainContainer"} id={sectionNames.practice}>
                    <div className={"mainTitle"}>
                        Áreas de Práctica
                    </div>
                    <div className={"mainButtons"}>
                        {width > 1024 ?
                            <>
                                <div className={"buttonContainer"}>
                                    <Cards title={"Litigios y arbitrajes"}
                                           description={"Nuestra experiencia en litigios cubre una completa gama en asuntos contenciosos, lo que incluye las etapas de asesoramiento, negociación, presentaciones judiciales y ejecución de acuerdos. Actuamos en temas litigiosos tanto en los fueros judiciales y administrativos de CABA y de la Provincia de Buenos Aires. Los principales ámbitos de la práctica del derecho son civil, comercial, laboral, seguros, concursos y quiebras, entre otros."}/>
                                    <Cards title={"Derecho societario"}
                                           description={"Brindamos asesoramiento de alta calidad acompañando a la sociedad desde el momento inicial de creación y consecuente registro, en el armado de documentos, transacciones de alta complejidad, e incluso en todos los conflictos que puedan presentarse y judicializarse en la dinámica societaria. Asimismo brindamos servicios en asuntos cotidianos como: constitución, cambio de autoridades, aumentos y reducciones de capital, negociación de la compra y venta de acciones, fusiones, disoluciones, liquidaciones, entre otros."}/>
                                    <Cards title={"Defensa del consumidor"}
                                           description={"B&A asesora a sus clientes, tanto del punto de vista de las empresas como de los consumidores, en cuestiones vinculadas a la normativa de defensa del consumidor ante los distintos fueros judiciales y administrativos. \n" +
                                               "Asesora a productores de bienes y servicios en áreas tales como productos farmacéuticos, alimenticios, seguros, automotrices, etc.\n" +
                                               "Nuestros servicios comprenden reclamos extrajudiciales y judiciales efectuados por el consumidor, asistencia a audiencias, reclamos referentes a la actividad aseguradora, garantías ofrecidas por proveedores, falla de producto, incumplimiento de deber de información, entre otros.\n"}/>
                                </div>
                                <div className={"buttonContainer"}>
                                    <Cards title={"Derecho laboral"}
                                           description={"Somos reconocidos por asesorar a empresas en casos complejos y sensibles, en asuntos laborales generales, acuerdos colectivos e individuales, contrataciones, despidos, desarrollo de procedimientos legales adecuados a las necesidades específicas de cada empresa, remuneraciones, seguridad social y otros beneficios.\n"}/>
                                    <Cards title={"Derecho de familia y sucesiones"}
                                           description={"Intervenimos en asuntos contenciosos, negociaciones privadas y resolución de conflictos complejos, siempre teniendo en cuenta la delicadeza de las disputas familiares.\n" +
                                               " Entre ellos se destacan:\n" +
                                               "Asesoramiento en cuestiones vinculadas al régimen patrimonial del matrimonio;\n" +
                                               "Procesos de divorcio, división de bienes, compensaciones económicas, régimen de tenencia;\n" +
                                               "Participación en procesos de determinación de la capacidad;\n" +
                                               "Sucesiones ab intestato y sucesiones testamentarias.\n"}/>
                                    <Cards title={"Concursos y quiebras"}
                                           description={"Brindamos asesoramiento a Pymes y empresas, armando planes de reestructuración de pasivos en APE, concursal y conversión de quiebra. "}/>
                                </div>
                                <div className={"buttonContainer"}>
                                    <Cards title={"Derecho administrativo"}
                                           description={"Brindamos un servicio integral que va desde la confección de opiniones legales y la negociación de contratos hasta la intervención en juicio en las distintas jurisdicciones y tribunales que correspondan. \n" +
                                               "Asesoramiento y representación en procedimientos de contratación administrativa (v.g. licitaciones públicas);\n" +
                                               "Asesoramiento en cuestiones general de derecho administrativo y regulación económica;\n" +
                                               "Consultoría general en materia de concesiones y arrendamientos con el Estado Nacional y/o organismos dependientes de éste;\n" +
                                               "Acciones de amparo contra actos del Poder Ejecutivo;\n" +
                                               "Defensa en procedimientos de ejecución fiscal;\n" +
                                               "Litigios en materia de derecho administrativo y constitucional, en sede administrativa y judicial.\n"}/>
                                    <Cards title={"Derecho comercial y empresario"}
                                           description={"Redacción de contratos comerciales de todo tipo;\n" +
                                               "Derecho de daños;\n" +
                                               "Títulos de crédito, incluyendo valores mobiliarios;\n" +
                                               "Asesoramiento y orientación respecto de prácticas comerciales relativas a compra, venta, locación o arrendamiento mercantil, representaciones comerciales, distribución y medidas de protección a los acreedores;\n" +
                                               "Protección contra el abuso de poder económico y competencia desleal;\n" +
                                               "Negociación de franquicias, elaboración de contratos y manuales;\n" +
                                               "Procesos de desalojo;\n" +
                                               "Recupero de créditos morosos;\n" +
                                               "Otros conflictos judiciales. \n"}/>
                                    <Cards title={"Propiedad intelectual"}
                                           description={"Los servicios destacados que ofrece nuestro equipo son:\n" +
                                               "Asuntos contenciosos;\n" +
                                               "Derechos de autor;\n" +
                                               "Marcas y patentes;\n" +
                                               "Licencias y cesiones de derechos.\n"}/>
                                </div>
                                <div className={"buttonContainer"}>
                                    <Cards title={"Derecho de seguros"} description={"\n" +
                                        "Brindamos asistencia en cuestiones de cobertura, operaciones y contratos de seguro, reclamos judiciales y extrajudiciales, seguros de responsabilidad y daños, entre otras.\n"}/>
                                    <Cards title={"Derecho inmobiliario"}
                                           description={"Brindamos asesoramiento en una amplia gama de servicios:\n" +
                                               "Asesoramiento referente a la titularidad de bienes inmuebles;\n" +
                                               "Consultoría y redacción de instrumentos vinculados con la transmisión y/o constitución de derechos reales sobre inmuebles;\n" +
                                               "Asesoramiento en el desarrollo de emprendimientos inmobiliarios;\n" +
                                               "Negociación de contratos de locación.\n"}/>
                                    <Cards title={"Derecho bancario y financiero"}
                                           description={"Nuestro asesoramiento cubre todo el espectro de temas bancarios, societarios y regulatorios, como así también todos los asuntos relacionados con la gestión de inversiones y los negocios bancarios.\n" +
                                               "Representamos a clientes en temas regulatorios y fintech, negociación y preparación de documentos correspondientes a operaciones de préstamos, consultoría general sobre contratos financieros, operaciones en el mercado de capitales, recuperación de cartera en mora, fideicomisos, operaciones con criptoactivos, entre otros. \n"}/>
                                </div>
                            </>
                            :
                            <>
                                <Cards title={"Litigios y arbitrajes"}
                                       description={"Nuestra experiencia en litigios cubre una completa gama en asuntos contenciosos, lo que incluye las etapas de asesoramiento, negociación, presentaciones judiciales y ejecución de acuerdos. Actuamos en temas litigiosos tanto en los fueros judiciales y administrativos de CABA y de la Provincia de Buenos Aires. Los principales ámbitos de la práctica del derecho son civil, comercial, laboral, seguros, concursos y quiebras, entre otros."}/>
                                <Cards title={"Derecho societario"}
                                       description={"Brindamos asesoramiento de alta calidad acompañando a la sociedad desde el momento inicial de creación y consecuente registro, en el armado de documentos, transacciones de alta complejidad, e incluso en todos los conflictos que puedan presentarse y judicializarse en la dinámica societaria. Asimismo brindamos servicios en asuntos cotidianos como: constitución, cambio de autoridades, aumentos y reducciones de capital, negociación de la compra y venta de acciones, fusiones, disoluciones, liquidaciones, entre otros."}/>
                                <Cards title={"Defensa del consumidor"}
                                       description={"B&A asesora a sus clientes, tanto del punto de vista de las empresas como de los consumidores, en cuestiones vinculadas a la normativa de defensa del consumidor ante los distintos fueros judiciales y administrativos. \n" +
                                           "Asesora a productores de bienes y servicios en áreas tales como productos farmacéuticos, alimenticios, seguros, automotrices, etc.\n" +
                                           "Nuestros servicios comprenden reclamos extrajudiciales y judiciales efectuados por el consumidor, asistencia a audiencias, reclamos referentes a la actividad aseguradora, garantías ofrecidas por proveedores, falla de producto, incumplimiento de deber de información, entre otros.\n"}/>
                                <Cards title={"Derecho laboral"}
                                       description={"Somos reconocidos por asesorar a empresas en casos complejos y sensibles, en asuntos laborales generales, acuerdos colectivos e individuales, contrataciones, despidos, desarrollo de procedimientos legales adecuados a las necesidades específicas de cada empresa, remuneraciones, seguridad social y otros beneficios.\n"}/>
                                <Cards title={"Derecho de familia y sucesiones"}
                                       description={"Intervenimos en asuntos contenciosos, negociaciones privadas y resolución de conflictos complejos, siempre teniendo en cuenta la delicadeza de las disputas familiares.\n" +
                                           " Entre ellos se destacan:\n" +
                                           "Asesoramiento en cuestiones vinculadas al régimen patrimonial del matrimonio;\n" +
                                           "Procesos de divorcio, división de bienes, compensaciones económicas, régimen de tenencia;\n" +
                                           "Participación en procesos de determinación de la capacidad;\n" +
                                           "Sucesiones ab intestato y sucesiones testamentarias.\n"}/>
                                <Cards title={"Concursos y quiebras"}
                                       description={"Brindamos asesoramiento a Pymes y empresas, armando planes de reestructuración de pasivos en APE, concursal y conversión de quiebra. "}/>
                                <Cards title={"Derecho administrativo"}
                                       description={"Brindamos un servicio integral que va desde la confección de opiniones legales y la negociación de contratos hasta la intervención en juicio en las distintas jurisdicciones y tribunales que correspondan. \n" +
                                           "Asesoramiento y representación en procedimientos de contratación administrativa (v.g. licitaciones públicas);\n" +
                                           "Asesoramiento en cuestiones general de derecho administrativo y regulación económica;\n" +
                                           "Consultoría general en materia de concesiones y arrendamientos con el Estado Nacional y/o organismos dependientes de éste;\n" +
                                           "Acciones de amparo contra actos del Poder Ejecutivo;\n" +
                                           "Defensa en procedimientos de ejecución fiscal;\n" +
                                           "Litigios en materia de derecho administrativo y constitucional, en sede administrativa y judicial.\n"}/>
                                <Cards title={"Derecho comercial y empresario"}
                                       description={"Redacción de contratos comerciales de todo tipo;\n" +
                                           "Derecho de daños;\n" +
                                           "Títulos de crédito, incluyendo valores mobiliarios;\n" +
                                           "Asesoramiento y orientación respecto de prácticas comerciales relativas a compra, venta, locación o arrendamiento mercantil, representaciones comerciales, distribución y medidas de protección a los acreedores;\n" +
                                           "Protección contra el abuso de poder económico y competencia desleal;\n" +
                                           "Negociación de franquicias, elaboración de contratos y manuales;\n" +
                                           "Procesos de desalojo;\n" +
                                           "Recupero de créditos morosos;\n" +
                                           "Otros conflictos judiciales. \n"}/>
                                <Cards title={"Propiedad intelectual"}
                                       description={"Los servicios destacados que ofrece nuestro equipo son:\n" +
                                           "Asuntos contenciosos;\n" +
                                           "Derechos de autor;\n" +
                                           "Marcas y patentes;\n" +
                                           "Licencias y cesiones de derechos.\n"}/>
                                <Cards title={"Derecho de seguros"} description={"\n" +
                                    "Brindamos asistencia en cuestiones de cobertura, operaciones y contratos de seguro, reclamos judiciales y extrajudiciales, seguros de responsabilidad y daños, entre otras.\n"}/>
                                <Cards title={"Derecho inmobiliario"}
                                       description={"Brindamos asesoramiento en una amplia gama de servicios:\n" +
                                           "Asesoramiento referente a la titularidad de bienes inmuebles;\n" +
                                           "Consultoría y redacción de instrumentos vinculados con la transmisión y/o constitución de derechos reales sobre inmuebles;\n" +
                                           "Asesoramiento en el desarrollo de emprendimientos inmobiliarios;\n" +
                                           "Negociación de contratos de locación.\n"}/>
                                <Cards title={"Derecho bancario y financiero"}
                                       description={"Nuestro asesoramiento cubre todo el espectro de temas bancarios, societarios y regulatorios, como así también todos los asuntos relacionados con la gestión de inversiones y los negocios bancarios.\n" +
                                           "Representamos a clientes en temas regulatorios y fintech, negociación y preparación de documentos correspondientes a operaciones de préstamos, consultoría general sobre contratos financieros, operaciones en el mercado de capitales, recuperación de cartera en mora, fideicomisos, operaciones con criptoactivos, entre otros. \n"}/>
                            </>
                        }
                    </div>
                </div>
            </div>
        </Section>


    )
}

export default PracticeAreas;