import React from "react";
import './TopBar.scss';
import NavBar from "../../common/NavBar/NavBar";
import {sectionNames} from "../../common/consts";

const TopBar = () => {
    return (
        <div id={sectionNames.top} style={{backgroundColor: "#213243"}}>
            <NavBar />
        </div>
    )
}

export default TopBar;
