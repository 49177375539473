import React from 'react';
import './Main.scss';
import TopBar from "../sections/Hero/TopBar";
import WhoWeAre from "../sections/WhoWeAre/WhoWeAre";
import ContactUs from "../sections/ContactUs/ContactUs";
import PracticeAreas from "../sections/PracticeAreas/PracticeAreas";

const Main = () => {
        return (
            <div className={'main'}>
                <TopBar/>
                <WhoWeAre/>
                <PracticeAreas/>
                <ContactUs/>
            </div>
        );
}

export default Main;
