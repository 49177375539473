import React, {useState} from "react";
import './ContactUs.scss';
import './style.css';
import 'leaflet/dist/leaflet.css';
import Section from "../../common/Section/Section";
import {ic_location_on} from 'react-icons-kit/md/ic_location_on';
import {Icon} from "react-icons-kit";
import {toast, ToastContainer} from "react-toastify";
import 'react-toastify/dist/ReactToastify.css';
import * as emailJS from "@emailjs/browser";
import {sectionNames} from "../../common/consts";
import {useWindowWidth} from "@react-hook/window-size";
import {Map, Marker, Popup, TileLayer} from 'react-leaflet'
import L from 'leaflet';
import ReCAPTCHA from "react-google-recaptcha";

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const ContactUs = () => {

    const width = useWindowWidth();

    let mobile = width < 1024;

    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [recaptchaValue, setRecaptchaValue] = useState('');

    const submit = () => {
        const templateParams = {
            name: name,
            phone: phone,
            email: email,
            message: message ? message : 'No message provided.',
            'g-recaptcha-response': recaptchaValue,
        };
        emailJS.send('service_x700ts5', 'template_nt0lejy', templateParams, 'kB1EcOH_cptBls-n6')
            .then((res) => {
                toast('Ya nos contactaremos con vos!')
            })
            .catch((err) => {
                toast('Ha ocurrido un error, por favor intenta nuevamente.')
            });
        setName('')
        setEmail('')
        setPhone('')
        setMessage('')
    }

    return (
        <Section id={sectionNames.contact} mobile={mobile} className={'contactUs'}>
            <div className={'contact-us-container'}>
                <div className={'contact-us-header'}>
                    Contactanos
                </div>
                <div className={'contact-us-info-container'}>
                    <div className={'contact-us-section left'}>
                        <input type={'text'} placeholder={'Nombre y Apellido'} className={'input'}
                               onChange={(e) => setName(e.target.value)}
                               value={name}/>
                        <input type={'text'} placeholder={'Email'} className={'input'}
                               onChange={(e) => setEmail(e.target.value)}
                               value={email}/>
                        <input type={'number'} placeholder={'Telefono de Contacto'} className={'input'}
                               onChange={(e) => setPhone(e.target.value)}
                               value={phone}/>
                        <textarea placeholder={'Mensaje...'} className={'input'}
                                  onChange={(e) => setMessage(e.target.value)}
                                  value={message}/>
                        <ReCAPTCHA
                            sitekey="6LfFsPYiAAAAAMhINEx_sOAIvdgMDQHgHyHMjV5o"
                            onChange={(value) => setRecaptchaValue(value)}
                        />
                        <button disabled={(!name || !email || !phone || !message)} style={{marginTop: "20px"}} onClick={() => submit()}>
                            Enviar
                        </button>
                    </div>
                    <div className={'contact-us-section right'}>
                        <div className={'info-container'} style={{height: "60%"}}>
                            {width > 1024 &&
                                <div className={'map'}>
                                    <Map center={[-34.504802, -58.479626]} zoom={15} scrollWheelZoom={false}>
                                        <TileLayer
                                            url='https://{s}.tile.osm.org/{z}/{x}/{y}.png'
                                        />
                                        <Marker position={[-34.504802, -58.479626]}>
                                            <Popup>Juan Bautista Alberdi 431<br/>9º piso</Popup>
                                        </Marker>
                                    </Map>
                                </div>
                            }
                        </div>
                        <div className={'info-container'}>
                            <Icon icon={ic_location_on} size={50}/>
                            <div className={'info'}>
                                <span>Juan Bautista Alberdi 431,</span>
                                <span>9º piso,</span>
                                <span>Puerto de Olivos</span>
                                <span>Vicente López, Buenos Aires.</span>
                                <a href="tel:+541148977113" className={"tel"}>+54 11 4897 7113</a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                closeButton={false}
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
        </Section>
    )
}

export default ContactUs;
