import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {useWindowWidth} from "@react-hook/window-size";

const Card = styled.div`
  background-color: ${({ isEmpty }) => (isEmpty ? "#FAFAFA" : "white")};
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  overflow: hidden;
  margin: 16px;
  width: 30%;
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  font-size: 24px;
  color: #213243;
  text-align: center;
  margin: 24px;
`;
const Footer = styled.div`
  width: 100%;
  height: 0; /* changed */
  font-size: 12px;
  line-height: 12px;
  color: #4f4f4f;
  display: flex;
  /* justify-content: center; */
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  transition: all 0.3s ease-in-out; /* added */
  overflow: hidden; /* added */

  span {
    padding: 12px 0;
  }

  .accordion {
    padding: 10px 15px 15px; /* changed */
    text-align: justify;
    line-height: 16px;
    font-size: 14px;
    font-family: 'Noto Serif-Regular', sans-serif;
  }

  &.show {
    height: ${({ setHeight }) => setHeight}px;
  }
`;


export const Cards = ({title, description}) => {
    const [expanded, setExpanded] = useState(false);
    const [accordionHeight, setAccordionHeight] = useState(0);
    const ref = useRef(null);
    const width = useWindowWidth();

    const open = () => setExpanded(!expanded);

    useEffect(() => {
        const getHeight = ref.current.scrollHeight;
        setAccordionHeight(getHeight);
    }, [expanded]);

    return (
        <>
            <Card
                onClick={open}
                style={{ width: width < 1024 ? "100%" : "30%" }}
            >
                <Header>{title}</Header>
                <Footer

                    className={expanded ? "show" : ""}
                    setHeight={accordionHeight}
                    ref={ref}
                >
                    <div className={"accordion"} ref={ref}>
                        {description}
                    </div>
                </Footer>
            </Card>
        </>
    );
};