import React from "react";
import './WhoWeAre.scss';
import {useWindowWidth} from "@react-hook/window-size";
import {sectionNames} from "../../common/consts";
import Section from "../../common/Section/Section";

const sectionStyle = {
    backgroundColor: '#F3F3F6',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    height: '120vh'
}

const sectionStyleMobile = {
    backgroundColor: '#F3F3F6',
    alignItems: 'center',
    width: "100%"
}
const text = [
    "Fundado en 1974, BORLENGHI & ASOCIADOS es un estudio jurídico integral, especializado en asesorar clientes nacionales e internacionales en temas de alta complejidad. ",
    "Con casi cincuenta años de experiencia y solidez, ofrecemos soluciones creativas, así como atención personalizada. El estudio tiene un trato directo con el cliente, escuchando sus necesidades y actuando de manera anticipada y estratégica, logrando resultados con rapidez y eficiencia. Comprendemos la necesidad de soluciones inmediatas, porque la justicia tardía, no es justicia. ",
    "Somos un estudio boutique que da soluciones a medida, sin delegar tareas, con asesoramiento directa y permanente con el socio, con la presencia fuera del Estudio o “in company” que corresponda al caso, y toda la contención personal necesaria por parte de los socios, valorando la consolidación de una relación a largo plazo. La excelencia de la prestación profesional y el servicio personalizado, son las prioridades esenciales que dan valor a nuestro compromiso permanente con la satisfacción del cliente. ",
    "Tenemos una amplia reputación por estar en constante adaptación a las nuevas tecnologías y desafíos, estando los profesionales en constante capacitación para brindar soluciones innovadoras y con una visión interdisciplinaria. ",
    "El pasado se encuentra con el futuro. Combinamos la experiencia obtenida por la larga trayectoria con la innovación para crear soluciones disruptivas y proactivas. ",
]

const WhoWeAre = () => {

    return (
        <Section expandable>
            <div>
                <div className={"who-image"}>
                    <div className={"who-image-text"}> Soluciones Innovadoras</div>
                </div>
                <div className={"who-textContainer"}>
                    <div className={"who-textTitle"} id={sectionNames.aboutUs}>
                        Quienes Somos
                    </div>
                    <div className={"who-textBodyContainer"}>
                    {text.map((text, id) => <div className={"who-textBody"} key={id}>{text} </div>)}
                    </div>
                </div>
            </div>
        </Section>


    )
}

export default WhoWeAre;