import React, {useEffect, useState} from "react";
import logo from '../../../assets/logoSize.svg';
import './NavBar.scss';
import {useWindowWidth} from "@react-hook/window-size";
import {sectionNames} from "../consts";


const NavBar = ({fixed}) => {

    const [prevScrollPos, setPrevScrollPos] = useState(window.pageYOffset);
    const [visible, setVisible] = useState(false);
    const [isOpen, setOpen] = useState(false);

    const toggleMenu = () => {
        setOpen(!isOpen);
    };

    const [className, setClassName] = useState('nav-container')

    const handleScroll = () => {
        const currentScrollPos = window.pageYOffset
        const visible = currentScrollPos < prevScrollPos
        setPrevScrollPos(currentScrollPos)
        setVisible(visible)
    }

    const scrollTo = (id) => {
        document.getElementById(id).scrollIntoView({behavior: "smooth"});
        setVisible(false);
    }


    //Hamburger menu
    const width = useWindowWidth();


    useEffect(() => {
        window.addEventListener('scroll', handleScroll)
    })

    useEffect(() => {
        const determineClassName = () => {
            if ((fixed && window.pageYOffset > window.innerHeight) || isOpen) {
                if (visible || isOpen) {
                    setClassName('fixed-nav-container')
                } else {
                    if ((window.pageYOffset > window.innerHeight) && (window.pageYOffset < window.innerHeight + 1000)) {
                        setClassName('fixed-nav-container hidden disable-transition')
                    } else {
                        setClassName('fixed-nav-container hidden')
                    }
                }
            } else {
                return setClassName('nav-container')
            }
        }
        determineClassName()
    }, [prevScrollPos, visible, fixed, isOpen])

    return (
        <div className={className}>
            <div className={'brand'} onClick={() => scrollTo(sectionNames.top)}> {/*Scroll to Top*/}
                    <img src={`${logo}`} alt={''}/>
            </div>
            <div className={'nav-items-container'}>
                <div className={'nav-item'} onClick={() => scrollTo(sectionNames.aboutUs)}>
                    Quienes Somos
                </div>
                <div className={'nav-item'} onClick={() => scrollTo(sectionNames.practice)}>
                    Áreas de Práctica
                </div>
                <div className={'nav-item'} onClick={() => scrollTo(sectionNames.contact)}>
                    Contactanos
                </div>
            </div>
        </div>
    )


}

export default NavBar;
