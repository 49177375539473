import React from "react";
import './Section.scss';

function getClassName(expandable, mobile) {
    if (mobile) return 'section-mobile-container'
    if (expandable) return 'section-expandable-container'
    return 'section-container';
}

const Section = ({children, expandable, mobile, id, style}) => {

    const className = getClassName(expandable, mobile)

    return (
        <div id={id}
             className={className}
             style={style}>
            {children}
        </div>
    )
}

export default Section;